.main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #000;
}

.draw-pad {
  width: 600px;
  height: 600px;
  border: 1px solid #ccc;
  margin-top: 5vh;
  background-color: #fff;
}

.side-buttons-container {
  margin-top: 5vh;
  margin-left: 5px;
  margin-right: 5px;
}

.side-logo-container {
  width: 96px;
  background-color: #26AAE1;
  padding-top: 1.5px;
  padding-bottom: 1.5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px 4px 0 0;
}

.side-logo {
  width: 90px;
}

.pen-button-container {
  /* display: flex; */
  /* flex-direction: row; */
  width: 96px;
  background: linear-gradient(180deg, #26AAE1 50%, #26AAE100 50%);
  
}

.pen-select-button {
  width: 32px;
}

.color-button-container {
  width: 96px;
}

.color-button {
  width: 48px;
  height: 48px;
  vertical-align: bottom;
}

.action-button-container {
  display: flex;
  flex-direction: column;
}

.edit-button-container {
  width: 96px;
}

.edit-button {
  /* Options to experiment with: */
  /* width: 48px; */
  width: 96px;
  vertical-align: bottom;
}

.download-button-container {
  width: 48px;
}

.download-button {
  width: 96px;
  vertical-align: bottom;
}

.push {
  animation: push 200ms 1;
}

@keyframes push {
  25% {
    transform:scale(0.95);
  }
  50% {
    transform:scale(0.90);
  }
  75% {
    transform:scale(0.85);
  }
  100% {
    transform:scale(0.80);
  }
}

.landing-header {
  height: 25%;
  background-color: #26AAE1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
}

.header-logo {
  margin: 1vh;
  margin-left: 10px;
}

.profile-main-container {
  color: #fff;
  margin: 10vh;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.profile-card-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  /* background-color: blue; */
}

.profile-card {
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  margin: 2vh;
  width: 350px;;
  /* background-color: purple; */
}

.profile-image {
  width: 250px;
  background-color: #fff;
  border-radius: 12px;
}

.not-found-anchor {
  background-color: #fff;
  padding: 2px;
  border-radius: 4px;
}

/* For modern phone portrait */
@media only screen and (max-width: 639px) {
  .header-logo {
    width: 200px;
  }

  .computer-demo {
    width: 75%;
  }
  
  .main-container {
    flex-direction: column;
    align-items: center;
  }
  
  .draw-pad {
    margin-top: 3vh;
    width: 300px;
    height: 300px;
  }

  .side-buttons-container {
    margin-top: 2vh;
  }

  .side-logo-container {
    width: 300px;
    vertical-align: bottom;
  }

  .side-logo {
    width: 270px;
    padding-top: 2px;
    
  }

  .pen-button-container {
    width: 300px;
  }

  .pen-select-button {
    width: 100px;
    padding-top: 2px;
    
  }

  .color-button-container {
    width: 300px;
  }

  .color-button {
    width: 75px;
    height: 75px;
    vertical-align: bottom;
  }

  .edit-button-container {
    /* flex-direction: row;
    justify-content: center;
    align-items: center; */
    text-align: center;
    width: 300px;
  }

  .download-button-container {
    text-align: center;
    width: 300px;
  }

  .profile-card-container {
    flex-direction: column;
  }

  .profile-card {
    margin-bottom: 10vh;
  }

}

/* For phone landscape view */
@media only screen and (max-height: 430px) {
  .draw-pad {
    width: 300px;
    height: 300px;
  }

  .side-buttons-container {
    width: 192px;
  }

  .side-logo-container {
    width: 192px;
  }

  .side-logo {
    width: 124px;
    padding: 8px;
  }

  .pen-button-container {
    width: 192px;
  }

  .pen-select-button {
    width: 64px;
  }

  .color-button-container {
    width: 192px;
  }

  .edit-button-container {
    width: 192px;
  }

  .download-button-container {
    width: 192px;
  }

  .download-button {
    width: 64px;
  }
}

/* For older phones like iPhone 6-8 */
@media only screen and (max-width: 415px) and (max-height: 746px) {
  .draw-pad {
    margin-top: 1vh;
  }
  
  .action-button-container {
    flex-direction: row;
  }

  .edit-button-container{
    width: 120px;
  }

  .edit-button {
    width: 60px;
  }

  .download-button-container {
    width: 180px;
  }

  .download-button {
    width: 60px;
  }
}

/* For Galaxy Fold */
@media only screen and (max-width: 281px) {
  .draw-pad {
    width: 250px;
    height: 250px;
  }

  .side-buttons-container {
    width: 250px;
  }

  .side-logo-container {
    width: 250px;
    justify-content: center;
    align-items: center;
    text-align: center;
    justify-self: center;
  }

  .side-logo {
    width: 220px;
  }

  .pen-button-container{
    width: 250px;
  }

  .pen-select-button {
    width: 83.33px;
  }

  .color-button-container {
    width: 250px;
  }

  .color-button {
    width: 62.5px;
    height: 62.5px;
  }
}

@media only screen and (max-height: 281px) {
  .draw-pad {
    width: 250px;
    height: 250px;
  }

  .side-logo {
    width: 85%;
    padding: 25px 0 25px 0;
  }

  .action-button-container {
    width: 192px;
    flex-direction: row;
    /* background-color: purple; */
  }

  .edit-button-container {
    width: 150px;
    /* background-color: red; */
  }

  .edit-button {
    width: 38px;
    margin: 0;
  }

  .download-button-container {
    width: 220px;
    /* background-color: blue; */
  }

  .download-button {
    width: 38px;
  }
}

